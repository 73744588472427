.detect__container {
    width: 100%;
    height: 100%;
    padding: 0px 20px 10px 10px;
    background-color: #F5F5F5;
}

.detect__breadcrumb {
    height: fit-content;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 10px 10px 0;
    justify-content: space-between
}

.detect__header {
    height: 7.5%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between
}

.detect__content {
    height: 85%;
    display: flex;
    flex-direction: row
}

.detect__footer {
    height: 5.5%;
    padding: 10px 0 10px 0;
    width: 100%;
    display: flex;
    justify-content: center
}

.detect-collections-card.ant-card {
    margin: 0 10px 10px;
    /*width: 40%;*/
    height: 220px;
    border-radius: 3px;
}

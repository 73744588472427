.detect__home__tab__container {
	padding: 0px 0px 10px 10px;
	margin: 5px;
}

.detect__home {
	width: 100%;
	height: 100%;
	padding: 10px 20px 10px 20px;
}

.detect__home__tab__pane {
	/* height: 100%; */
}

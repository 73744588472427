.ant-tabs-nav {
    width: 100%;
}
.ant-tabs-tab-btn {
    width: 100%;
}
.exception-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 60%;
}

.exception-count {
    width: 20%;
    font-weight:bold
}

.server-exception-code {
    width: 20%;
    color:#ff352c;
}

.client-exception-code {
    width: 20%;
    color:#faad14;
}
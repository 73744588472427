.detect-card-footer__key {
	font-weight: bold;
	font-size: 9pt;
	color: #8c8c8c;
	display: inline;
	margin-left: 10px;
}

.detect-card-footer__value {
	font-size: 9pt;
	color: #595959;
	display: inline;
}

.detect-full-center {
	height: 100%;
	width: 100%;
	margin: 5px 0 5px 0;
	display: flex;
	justify-content: center;
	align-items: center;
}

.detect-list-scroll {
	width: 100%;
	height: fit-content;
	max-height: 100%;
	overflow-y: scroll;
}

.detect-full-width-center {
	width: 100%;
	margin-top: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.detect-full-width-center--no-margin {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.detect-item-card.ant-card {
	margin: 0 10px 10px 10px;
	width: 100%;
	height: 120px;
	border-radius: 3px;
}

.detect-item-card__body {
	display: flex;
	flex-direction: row;
	padding-bottom: 10px;
}

.detect-item-card__footer {
	width: 100%;
	display: flex;
	flex-direction: row;
	gap: 20px;
	margin-top: 10px;
}

.detect-item-card__title {
	width: fit-content;
	display: flex;
	flex-direction: column;
	flex-grow: 1;
}

.detect-item-card__summary {
	width: max-content;
	display: flex;
	flex-direction: column;
}

.detect-button--primary.ant-btn {
	display: flex;
	justify-content: center;
	align-items: center;
	border: 1px solid #2f4dd3;
	color: #2f4dd3;
	cursor: pointer;
}

.detect-button--primary.ant-btn:hover {
	display: flex;
	justify-content: center;
	align-items: center;
	border: 1px solid #2f4dd3;
	background-color: #2f4dd3;
	color: white;
	cursor: pointer;
}

.detect-button--primary--full {
	display: flex;
	justify-content: center;
	align-items: center;
	border: 1px solid #2f4dd3;
	color: #2f4dd3;
	width: 100%;
	cursor: pointer;
}

.detect-button--primary--full:hover {
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: center;
	border: 1px solid #2f4dd3;
	background-color: #2f4dd3;
	color: white;
	cursor: pointer;
}

.detect-button--danger {
	display: flex;
	justify-content: center;
	align-items: center;
	border: 1px solid red;
	color: red;
	cursor: pointer;
}

.detect-button--danger:hover {
	display: flex;
	justify-content: center;
	align-items: center;
	border: 1px solid red;
	background-color: red;
	color: white;
	cursor: pointer;
}

.detect-button--danger--full {
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: center;
	border: 1px solid red;
	color: red;
	cursor: pointer;
}

.detect-button--danger--full:hover {
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: center;
	border: 1px solid red;
	background-color: red;
	color: white;
	cursor: pointer;
}

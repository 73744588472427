.detect__home__tab__container {
	width: 100%;
	height: 100%;
	padding: 0px 0px 10px 15px;
	margin: 5px;
}

.detect__home {
	width: 100%;
	height: 100%;
	padding: 10px 20px 10px 20px;
}

.detect__home__tab__pane {
	width: 100%;
	height: 100%;
	/*height: 100%;*/
}

.detect__container {
    width: 100%;
}

.detect__breadcrumb {
    height: fit-content;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 10px 10px 0;
    justify-content: space-between
}

.detect__header {
    height: 7.5%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between
}

.detect__content {
    height: 70%;
    display: flex;
    flex-direction: row;
    overflow-y: scroll;
}

.detect__footer {
    height: 5.5%;
    padding: 10px 0 10px 0;
    width: 100%;
    display: flex;
    justify-content: center
}

.detect-list-scroll1 {
	width: 100%;
	height: 100%;
	overflow-y: scroll;
}

.detect-admin__body {
    height: 89%;
    width: 100%;
    overflow-y: scroll;
}

.detect-item__loading {
	margin: 10px;
	width: 50vw;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.detect-item__form.ant-form {
	height: 60vh;
	padding: 10px 20px 20px;
	width: 55vw;
	overflow-y: scroll;
}

.detect-item__body {
	margin-bottom: 10px;
}
.detect-item__footer {
	bottom: 0;
	left: 0;
	width: 100%;
	padding: 0;
	height: fit-content;
}


@import './main.css';

html {
}

body {
	margin: 0;
	font-family: 'Lato', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
		'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
		'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

/* width */

::-webkit-scrollbar {
	width: 5px;
	height: 5px;
}

/* Track */

::-webkit-scrollbar-track {
	background: #f1f1f1;
}

/* Handle */

::-webkit-scrollbar-thumb {
	background: #888;
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
	background: #555;
}

.w-md-editor-text-input::selection {
	background-color: rgb(47, 77, 211, 0.3) !important;
}

body {
	overflow: hidden;
}

.detect-item__loading {
	margin: 10px;
	width: 50vw;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.detect-item__form.ant-form {
	height: 60vh;
	padding: 10px 20px 20px;
	width: 50vw;
	overflow-y: scroll;
}

.detect-item__body {
	margin-bottom: 10px;
}
.detect-item__footer {
	bottom: 0;
	left: 0;
	width: 100%;
	padding: 0;
	height: fit-content;
}

.detect-button--selected.ant-btn {
	display: flex;
	justify-content: center;
	align-items: center;
	border: 1px solid #4bb543;
	color: #4bb543;
	cursor: pointer;
}

.ReactFlow {
	flex-grow: 1;
	height: 39vh;
	background: linear-gradient(90deg, white (15px - 4px), transparent 1%) center, linear-gradient(white (15px - 4px), transparent 1%) center, #f6f7fc;
	/* background-size: 15px 15px; */
	border-radius: 10px;
	margin: 3% 0;
}

.SourceHandle {
	width: 100% !important;
	height: 100% !important;
	position: absolute !important;
	top: 0 !important;
	left: 0 !important;
	border-radius: 0 !important;
	transform: none !important;
	border: none !important;
	opacity: 0 !important;
}

.TargetHandle {
	width: 100% !important;
	height: 100% !important;
	background: blue !important;
	position: absolute !important;
	top: 0 !important;
	left: 0 !important;
	border-radius: 0 !important;
	transform: none !important;
	border: none !important;
	opacity: 0 !important;
}

.EdgeBody body {
	background: transparent;
	width: 100%;
	height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 40px;
}

.EdgeDiv {
	width: 100%;
	background: #eee !important;
	border: 1px solid #fff;
	cursor: pointer;

	font-size: 12px;
	line-height: 1;
}

.EdgeSuccess {
	width: 100%;
	background: #4bb543 !important;
	border: 1px solid #fff !important;
	color: white !important;
	cursor: pointer;

	font-size: 12px;
	line-height: 1;
}

.Name {
	/* Title/Title Text . .Bold */
	font-family: 'Lato';
	font-style: normal;
	font-weight: 700;
	font-size: 20px;
	line-height: 24px;
	/* identical to box height */
	margin-top: 1rem;
	align-items: center;
	letter-spacing: 0.005em;
	text-transform: capitalize;
	padding-left: 1rem;
	/* Black/B-90 */
	display: flex;
	color: #1e2126;
}

.Heading {
	/* Title/Title Text . .Bold */
	font-family: 'Lato';
	font-style: normal;
	font-weight: 700;
	font-size: 18px;
	line-height: 24px;
	justify-content: center;
	/* identical to box height */
	margin-top: 1rem;
	align-items: center;
	letter-spacing: 0.005em;
	text-transform: capitalize;
	padding-left: 1rem;
	/* Black/B-90 */
	display: flex;
	color: #1e2126;
}

.Content {
	padding-left: 0rem;
}

.RightBar {
	width: 25rem;
	height: 92vh;
	border-left: 1.5px solid #e7ecf5;
}

.FlowArea {
	width: 75rem;
}

.OKNode {
	box-sizing: border-box;

	/* Auto layout */
	cursor: grab;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 8px 16px;
	gap: 12px;

	width: 230px;
	height: 50px;

	background: #ffffff;
	/* Gray/Line_Border */

	border: 1px solid #e7ecf5;
	border-radius: 8px;

	/* Inside auto layout */

	flex: none;
	order: 1;
	flex-grow: 0;
	margin-bottom: 10%;
}

.Node {
	box-sizing: border-box;

	/* Auto layout */
	cursor: default;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 8px 16px;
	gap: 12px;

	width: 230px;
	height: 50px;

	background: #ffffff;
	/* Gray/Line_Border */

	border: 1px solid #e7ecf5;
	border-radius: 8px;

	/* Inside auto layout */

	flex: none;
	order: 1;
	flex-grow: 0;
}
.OkHeading {
	font-family: 'Lato';
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 16px;
	margin-bottom: 5px;
	/* identical to box height */

	letter-spacing: 0.005em;
	text-transform: capitalize;

	/* Black/B-80 */

	color: #333840;

	/* Inside auto layout */

	flex: none;
	order: 0;
	flex-grow: 0;
}

.EndpointString {
	font-family: 'Lato';
	font-style: normal;
	font-weight: 600;
	font-size: 10px;
	line-height: 12px;
	/* identical to box height */

	letter-spacing: 0.005em;
	text-transform: none;

	/* Black/B-80 */

	color: #5a5a5a;

	/* Inside auto layout */

	flex: none;
	order: 0;
	flex-grow: 0;
}

.SideEntity {
	display: flex;
	flex-direction: column;
	height: 80%;
	margin-top: 10%;
	align-items: center;
	overflow: scroll;
	margin-bottom: 3%;
}

.homepage-heading {
	font-family: 'Lato';
	font-style: normal;
	font-weight: 900;
	font-size: 27px;
	line-height: 46px;

	color: #282f3f;
}

.homepage-heading-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.homepage-create-new.ant-btn {
	display: flex;
	justify-content: center;
	align-items: center;
	font-family: 'Lato';
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 24px;
	cursor: pointer !important;
	width: 138px;
	height: 40px;
}

.homepage-create-new.ant-btn svg {
	margin-right: 6px;
	margin-top: 2px;
}

.homepage-container .ant-table {
	border-radius: 0 !important;
}

.homepage-container .ant-table-cell {
	border-radius: 0 !important;
}

.new-product-container {
	position: absolute;
	height: 400px;
	width: 279px;
	border-radius: 8px;
	background-color: white;
	top: 48px;
	right: 0;
	z-index: 999;
	border: 1px solid #eeeff0;
	box-shadow: 0px 4px 8px rgba(230, 220, 220, 0.25);
	padding: 20px;
}

.relative-container {
	position: relative;
}

.new-product-container-title {
	display: flex;
	align-items: center;
	font-family: 'Lato';
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 19px;

	/* Gray / gray-7 */

	color: #8c8c8c;
	margin-bottom: 23px;
}

.new-product-container-title svg {
	margin-right: 2px;
}

.creatable-flex {
	display: flex;
	padding-top: 16px;
	padding-bottom: 10px;
	cursor: pointer;
}

.creatable-flex img {
	width: 28px;
	height: 28px;
}

.creatable-flex div {
	margin-left: 14px;
	font-family: 'Lato';
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 22px;
	color: #282f3f;
}

.detect-graph-card {
	margin: 5px;
	width: 99%;
	height: fit-content;
	border-radius: 3px;
}

.detect-graph-card__header {
	text-align: center;
}

.detect-graph-card__body {
	display: flex;
	flex-direction: row;
	width: 100%;
	justify-content: center;
}

.detect-graph-stats {
	max-width: 15%;
	width: fit-content;
	padding-left: 10px;
	flex-grow: 1;
}

.detect-graph-stats__title {
	font-size: 9pt;
	color: #777777;
}

.detect-graph-stats__body {
	font-weight: 350;
	font-size: 12pt;
}

.detect-graph-stats__table {
	width: 100%;
	height: fit-content;
	text-align: left;
	vertical-align: top;
}

.detect-center-min-height {
	padding: 10px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.detect-item-dashboard {
	flex-direction: column;
	display: flex;
	width: 99%;
	padding: 0.5%;
	height: 90vh;
	overflow-y: scroll;


}

.detect-item-dashboard-card {
	background-color: white;
	padding: 5px;
	width: 100%;
	border-radius: 3px;
	display: flex;
	flex-direction: column;
}

.detect-item-permission {
	width: 100%;
	padding: 20px;
	height: 80%;
	display: flex;
	flex-direction: column;
}

.detect-user-permission {
	width: 100%;
	display: flex;
	padding: 5px;
	border-radius: 5px;
	justify-content: space-between;
}

.detect-drilldown-graphs {
	max-height: 450px;
	overflow: auto;
	width: 100%;
}

.detect-run-log-table .ant-table table {
	font-size: 11px;
}

.detect-anomaly-definition-table .ant-table table {
	font-size: 10px;
}

.detect-item-name {
	color: #000000;
	align-self: start;
	font-family: 'Lato';
	vertical-align: top;
	font-style: normal;
	font-weight: 700;
	font-size: 22px;
}

.g6-tooltip {
    border-radius: 6px;
    font-size: 12px;
    color: #fff;
    background-color: #000;
    padding: 2px 8px;
    text-align: center;
  }

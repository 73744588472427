.navbar-main {
	height: 60px;
	box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
	width: calc(100%);
	/*padding: 15px 61px 15px 16px;*/
	/*padding-left: 0;*/
	display: flex;
	justify-content: space-between;
	align-items: center;
	/*z-index: 999;*/
	background-color: white;
	position: absolute;
}
.navbar-main-product-name {
	font-family: 'Lato', serif;
	font-style: normal;
	font-weight: 800;
	font-size: 22px;
	line-height: 19px;

	color: #000000;
}

.navbar-main-company-name {
	font-family: 'Lato', serif;
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	margin-top: 5px;
	line-height: 10px;
}
.navbar-menu-item:hover {
	background-color: #e6e6e6;
	border-radius: 2px;
	/*max-height: 100%;*/
	padding-left: 10px;
	padding-right: 10px;
	font-weight: bolder;
}

.navbar-menu-item {
	border-color: #020202;
	padding-left: 10px;
	padding-right: 10px;
	font-weight: bolder;
	/*max-height: 100%;*/
	min-width: 70px;
	border-width: 5px;
	color: #020202
}

.navbar-input {
	width: 549px !important;
	display: flex;
	border-radius: 6px !important;
}

.ant-select-item-empty {
	display: flex !important;
	justify-content: center !important;
}

.navbar-input-icon {
	color: #8c8c8c !important;
}

.navbar-user {
	display: flex;
	align-items: center;
	position: relative;
}

.navbar-user svg {
	color: #595959;
}

.navbar-product-icon {
	margin-right: 17px;
	width: 28px;
	height: 28px;
	background: #f5f5f5;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
}

.navbar-product-help {
	margin-right: 33px;
	width: 28px;
	height: 28px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.navbar-product-image img {
	width: 32px;
	height: 32px;
	border-radius: 50%;
	margin-right: 8px;
	display: flex;
	align-items: center;
}

.navbar-product-image {
	display: flex;
	align-items: baseline;
}

.navbar-product-image svg {
	margin-top: 2px;
	margin-left: 3px;
}

.navbar-product-user {
	font-family: 'Lato';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 22px;

	color: #262626;
}

.navbar-user * {
	cursor: pointer;
}

.autocomplete-search-option {
	padding-top: 11px;
	padding-bottom: 11px;
	display: flex;
	align-items: end;
	cursor: pointer;
}

.search-entity-image {
	height: 20px;
	width: 20px;
	margin-right: 11px;
	border-radius: 2px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.navbar-main .ant-select .ant-select-selector {
	background-color: #f2f3f4 !important;
	color: #8c8c8c !important;
}

.navbar-main .ant-select-selection-placeholder {
	color: #8c8c8c !important;
}

.ant-select-item-empty {
	justify-content: start !important;
}

.logo-search-container {
	display: flex;
	align-items: center;
}

.sidebar-main__header-open-logo-text {
	margin-right: 67px;
	margin-left: -500px;
}

.navbar-product-image .ant-avatar {
	width: 32px;
	height: 32px;
	border-radius: 50%;
	margin-right: 8px;
}

.logout-bar-container {
	position: absolute;
	display: flex;
	justify-content: end;
	right: 61px;
	top: 60px;
	cursor: pointer;
	border-radius: 8px;
	background-color: white;
	z-index: 999;
	border: 1px solid #eeeff0;
	box-shadow: 0px 4px 8px rgba(230, 220, 220, 0.25);
	padding: 20px;
}

/* TODO: Change this to non negative margins */
.sidebar-main__header__logo img {
	width: 113px;
	height: 30px;
	margin-right: -70px;
	margin-left: -10px;
}

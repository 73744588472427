.mlp-item-permission {
    width: fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    padding: 10px;
}

.mlp-user-permission {
    width: 100%;
    display: flex;
    padding: 5px;
    border-radius: 5px;
    justify-content: space-between
}


.mlp-full-width-center {
    width: 100%;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}


.mlp-button--primary {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #2F4DD3;
    color: #2F4DD3;
    cursor: pointer
}

.mlp-button--primary:hover {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #2F4DD3;
    background-color: #2F4DD3;
    color: white;
    cursor: pointer
}

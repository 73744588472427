.detect-admin__container {
    background-color: #F5F5F5;
    padding: 10px 20px 20px;
    height: 100%;
    width: 100%
}

.detect-admin__header {
    height: 5%;
    font-size: 24pt;
    font-weight: 500
}

.detect-admin__body {
    height: 89%;
    width: 100%;
    overflow-y: scroll;
}

.detect-admin__footer {
    height: 5%;
    width: 100%;
    display: flex;
    justify-content: center
}
